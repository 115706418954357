import { getHours, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const msToTime = (s, isDecimal = false) => {
	const dateTemp = utcToZonedTime(new Date(s), 'UTC')
	const hours = getHours(dateTemp)
	const timeString = format(dateTemp, `${hours - 1 > 0 ? 'HH:' : ''}mm:ss${isDecimal ? '.S' : ''}`)
	return timeString
}

export const msToSeconds = s => {
	const result = s / 1000
	return Math.round(result)
}

export const msToMinutesCH = s => {
	let result
	const seconds = s / 1000
	const minutes = s / 1000 / 60
	if (seconds < 60 && seconds >= 0) {
		result = 0
	} else {
		result = minutes
	}
	return result
}

export const msToSecondsRT = s => {
	const result = s / 1000
	return result
}

export const timeToMs = time => {
	let string = ''
	let hours = 0
	let minutes = 0
	let seconds = 0
	let milliseconds = 0
	string = time
	const date = new Date(0)
	const timeTemp = string.split(':')
	if (timeTemp.length === 3) {
		hours = timeTemp[0]
		minutes = timeTemp[1]
		seconds = timeTemp[2]
	} else if (timeTemp.length === 2) {
		hours = 0
		minutes = timeTemp[0]
		seconds = timeTemp[1]
	}

	if (seconds.indexOf('.') !== -1) {
		const arrayMilli = seconds.split('.')
		seconds = arrayMilli[0]
		milliseconds = arrayMilli[1] * 100
	}
	date.setUTCHours(hours, minutes, seconds, milliseconds)
	return date.getTime()
}

export const secondsToMs = time => {
	return time * 1000
}

export const pointsToTime = (points, isDecimal = false, type, precision) => {
	const dateTemp = utcToZonedTime(new Date(points * 1000), 'UTC')
	const timePattern = isDecimal
		? `${points >= 3600 ? 'HH:' : ''}mm:ss${
				precision === 3 ? '.SSS' : precision === 2 ? '.SS' : precision === 1 ? '.S' : ''
		  }`
		: `${points >= 3600 ? 'HH:' : ''}mm:ss`

	const result = !points ? '' : format(dateTemp, timePattern)

	return result
}
