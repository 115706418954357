import { useContext } from 'react'
import ConfigContext from '../contexts/Config'

const useConfig = () => {
	const context = useContext(ConfigContext)

	return context
}

export default useConfig
