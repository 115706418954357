import { useContext } from 'react'
import RacesContext from '../contexts/Races'

const useRaces = () => {
	const context = useContext(RacesContext)

	return context
}

export default useRaces
