import { useContext } from 'react'
import OptionsContext from '../contexts/Options'

const useOptions = () => {
	const context = useContext(OptionsContext)

	return context
}

export default useOptions
