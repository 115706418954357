import { useEffect, useRef } from 'react'
import { Image } from 'cloudinary-react'
import { LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

const Logo = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
`
const Loader = () => {
	const isMounted = useRef(true)
	const rallyCode = process.env.REACT_APP_RALLY_CODE

	useEffect(() => {
		isMounted.current = true

		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Logo className="absolute" style={{ textAlign: 'center', marginTop: '30px' }}>
			<Image publicId={`races/${rallyCode}.png`} key={rallyCode} width="180" height="80" />
			<br />
			LOADING
			<LinearProgress color="primary" />
		</Logo>
	)
}

export default Loader
