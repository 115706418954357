import { Image } from 'cloudinary-react'
import { useTimer } from 'react-timer-hook'
import TimerStyled from './TimerStyled'

const Timer = ({ expiryTimestamp }) => {
	const { seconds, minutes, hours, days } = useTimer({
		expiryTimestamp,
		autoStart: true,
	})

	const rallyCode = process.env.REACT_APP_RALLY_CODE
	const logoPath = 'races/' + rallyCode + '.png'
	return (
		<div style={{ textAlign: 'center', marginTop: '30px' }}>
			<Image publicId={logoPath} width="180" height="80" />
			<TimerStyled seconds={seconds} minutes={minutes} hours={hours} days={days} />
		</div>
	)
}

export default Timer
