import ReactDOM from 'react-dom'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { CloudinaryContext } from 'cloudinary-react'
import { Coming } from 'react-coming'
import { format } from 'date-fns'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { matomoSites } from './utils/matomo'
import './index.css'
import '@fontsource/roboto'
import App from './App'
import Timer from './components/Timer/Timer'
import * as serviceWorker from './serviceWorker'

const cloudinaryCloudName = 'jbtc'
const environment = process.env.NODE_ENV
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const MATOMO_URL = process.env.REACT_APP_MATOMO_URL
const rallyCode = process.env.REACT_APP_RALLY_CODE
const rallyStart = process.env.REACT_APP_RALLY_START
const rallyStartDate = new Date(rallyStart)
const now = new Date()

const uri =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:4100'
		: `https://${rallyCode.toLowerCase()}-server.vercel.app`

const httpLink = new HttpLink({
	uri: uri,
})

const retryIf = (error, operation) => {
	const doNotRetryCodes = [500, 400]
	return !!error && !doNotRetryCodes.includes(error.statusCode)
}
const retryLink = new RetryLink({
	delay: {
		initial: 100,
		max: 2000,
		jitter: true,
	},
	attempts: {
		max: 20,
		retryIf,
	},
})

const links = ApolloLink.from([retryLink, httpLink])

/*
const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
}
*/
const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: links,
	//defaultOptions: defaultOptions,
})

const iconCode = 'JBTC2020' // || rallyCode
document.title = `${rallyCode}${rallyCode === 'DKC2021' ? '' : ' by JB Time Concept'}`
document.getElementById(
	'faviconApple'
).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_180,w_180,b_rgb:fff,bo_20px_solid_white/races/${iconCode}_favicon.png`
document.getElementById(
	'favicon16'
).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_16,w_16/races/${iconCode}_favicon.png`
document.getElementById(
	'favicon32'
).href = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/c_fill,h_32,w_32/races/${iconCode}_favicon.png`

Sentry.init({
	dsn: SENTRY_DSN,
	integrations: [new BrowserTracing({ tracingOrigins: [uri] })],
	tracesSampleRate: 1.0,
})

const matomoSiteID = matomoSites.filter(site => site.code === rallyCode)[0].id

const matomoInstance = createInstance({
	urlBase: MATOMO_URL,
	siteId: matomoSiteID,
	//userId: 'UID76903202', // optional, default value: `undefined`.
	//trackerUrl: `${REACT_APP_MATOMO_URL}/tracking.php`, // optional, default value: `${urlBase}matomo.php`
	//srcUrl: `${REACT_APP_MATOMO_URL}/tracking.js`, // optional, default value: `${urlBase}matomo.js`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	heartBeat: {
		// optional, enabled by default
		active: true, // optional, default value: true
		seconds: 10, // optional, default value: `15
	},
	linkTracking: false, // optional, default value: true
	configurations: {
		// optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
		setSecureCookie: environment === 'production',
		setRequestMethod: 'POST',
	},
})

ReactDOM.render(
	<MatomoProvider value={matomoInstance}>
		<ApolloProvider client={client}>
			<CloudinaryContext cloudName={cloudinaryCloudName}>
				<Coming
					toDate={format(rallyStartDate, 'yyyy-MM-dd')}
					toTime={format(rallyStartDate, 'HH:mm')}
					enabled={environment === 'production' && rallyStartDate > now}
					customComponent={<Timer expiryTimestamp={rallyStartDate} />}
				>
					<App />
				</Coming>
			</CloudinaryContext>
		</ApolloProvider>
	</MatomoProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
