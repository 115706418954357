import { msToTime } from './timeConverter'
const rallyCode = process.env.REACT_APP_RALLY_CODE
const timeRallies = ['TA2022']
const decimalRallies = []
const isTimeTotal = timeRallies.some(elem => elem === rallyCode)
const isDecimal = decimalRallies.some(elem => elem === rallyCode)

export const filterRTWithPosition = (rows, id, filterValue) => {
	if (filterValue !== 'reset') {
		return rows
			.filter(row => {
				const rowValue = row.values[id]
				return rowValue.toString() === filterValue.toString()
			})
			.map((row, i, mapRows) => {
				row.values['position'] = i + 1
				let differencePrevious = i > 0 ? row.original.total - mapRows[i - 1].original.total : null
				differencePrevious =
					differencePrevious === null
						? null
						: differencePrevious === 0
						? '='
						: Number(differencePrevious.toFixed(2))
				differencePrevious =
					isTimeTotal && typeof differencePrevious === 'number'
						? '+' + msToTime(differencePrevious * 1000, isDecimal)
						: typeof differencePrevious === 'number'
						? '+' + differencePrevious
						: differencePrevious
				row.values['difPrevious'] = differencePrevious
				const firstTotal = i > 0 ? mapRows[0].original.total : 0
				const currentTotal = row.original.total
				let differenceFirst = i > 0 ? currentTotal - firstTotal : null
				differenceFirst =
					differenceFirst === null
						? null
						: differenceFirst === 0
						? '='
						: Number(differenceFirst.toFixed(2))
				differenceFirst =
					isTimeTotal && typeof differenceFirst === 'number'
						? '+' + msToTime(differenceFirst * 1000, isDecimal)
						: typeof differenceFirst === 'number'
						? '+' + differenceFirst
						: differenceFirst
				row.values['difFirst'] = differenceFirst
				return row
			})
	} else {
		return rows.map((row, i) => {
			row.values['position'] = row.original.position
			row.values['difPrevious'] = row.original.difPrevious
			row.values['difFirst'] = row.original.difFirst
			return row
		})
	}
}

export const filterOVWithPosition = (rows, id, filterValue) => {
	if (filterValue !== 'reset') {
		return rows
			.filter(row => {
				const rowValue = row.values[id]
				return rowValue.toString() === filterValue.toString()
			})
			.map((row, i, mapRows) => {
				row.values['position'] = i + 1
				let differencePrevious =
					i > 0 ? row.original.totalOV - mapRows[i - 1].original.totalOV : null
				differencePrevious =
					differencePrevious === null
						? null
						: differencePrevious === 0
						? '='
						: Number(differencePrevious.toFixed(2))
				differencePrevious =
					isTimeTotal && typeof differencePrevious === 'number'
						? '+' + msToTime(differencePrevious * 1000, isDecimal)
						: typeof differencePrevious === 'number'
						? '+' + differencePrevious
						: differencePrevious
				row.values['difPrevious'] = differencePrevious
				const firstTotal = i > 0 ? mapRows[0].original.totalOV : 0
				const currentTotal = row.original.totalOV
				let differenceFirst = i > 0 ? currentTotal - firstTotal : null
				differenceFirst =
					differenceFirst === null
						? null
						: differenceFirst === 0
						? '='
						: Number(differenceFirst.toFixed(2))
				differenceFirst =
					isTimeTotal && typeof differenceFirst === 'number'
						? '+' + msToTime(differenceFirst * 1000, isDecimal)
						: typeof differenceFirst === 'number'
						? '+' + differenceFirst
						: differenceFirst
				row.values['difFirst'] = differenceFirst
				return row
			})
	} else {
		return rows.map((row, i) => {
			row.values['position'] = row.original.position
			row.values['difPrevious'] = row.original.difPrevious
			row.values['difFirst'] = row.original.difFirst
			return row
		})
	}
}
