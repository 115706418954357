import { useState, createContext } from 'react'

const RacesContext = createContext()

const defaultRaces = null

export const RacesProvider = ({ children, races }) => {
	const [currentRaces] = useState(races || defaultRaces)

	return <RacesContext.Provider value={{ races: currentRaces }}>{children}</RacesContext.Provider>
}

//export const RaceConsumer = RaceContext.Consumer

export default RacesContext
