import { FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	root: {
		paddingTop: 25,
	},
	darkButton: {
		visibility: 'hidden',
	},
})

function MatomoFrame() {
	return (
		<div>
			<iframe
				title="matomo"
				style={{ border: '0', height: '200px', width: '600px' }}
				src="https://analytics.server.jbtc.be/index.php?module=CoreAdminHome&action=optOut&language=en"
			></iframe>
		</div>
	)
}

function Settings({ isDarkMode, themeChange }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<FormGroup row className={classes.darkButton}>
				<FormControlLabel
					control={
						<Switch
							checked={isDarkMode}
							onChange={e => themeChange(e.target.checked)}
							name="isDarkMode"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							disabled
						/>
					}
					label="Dark mode"
					labelPlacement="start"
				/>
			</FormGroup>
			<MatomoFrame></MatomoFrame>
		</div>
	)
}

export default Settings
