import en from './en-GB'
import fr from './fr-FR'
import es from './es-ES'
import ar from './ar-SA'
import de from './de-DE'
import nl from './nl-NL'

const languages = {
	...en,
	...fr,
	...es,
	...ar,
	...de,
	...nl,
}

export default languages
