import { useState, useEffect } from 'react'
import { Router, Location } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { CORE_CONFIG_FIELDS } from './utils/fragments'
import { ConfigProvider } from './contexts/Config'
import { OptionsProvider } from './contexts/Options'
import { RacesProvider } from './contexts/Races'
import Loader from './components/Loader/Loader'
import { IntlProvider } from 'react-intl'
import { LOCALES } from './i18n/locales'
import messages from './i18n/messages'
import {
	createTheme,
	ThemeProvider,
	makeStyles,
	StylesProvider,
	jssPreset,
} from '@material-ui/core/styles'
import { CssBaseline, Toolbar } from '@material-ui/core'
import CookieConsent from 'react-cookie-consent'
import { create } from 'jss'
import rtl from 'jss-rtl'
import {
	Root,
	getHeader,
	getDrawerSidebar,
	getSidebarTrigger,
	getSidebarContent,
	getCollapseBtn,
	getContent,
	getStandardScheme,
} from '@mui-treasury/layout'
import { NavContent, AppHeader } from './components/MUILayout'
import { Departures, RankingOV, RankingRT, RankingCHS, Pdf, StartList, Settings } from './views'
import { useStateWithLocalStorage } from './hooks'
import styled from 'styled-components'

const CONFIG_QUERY = gql`
	${CORE_CONFIG_FIELDS}
	query {
		config {
			...CoreConfigFields
		}
	}
`
const RACES_QUERY = gql`
	query {
		races {
			id
			name
			text
			isGroup
			isClass
			isCoef
			isAvg
			isScratch
		}
	}
`

const Header = getHeader(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const SidebarContent = getSidebarContent(styled)
const CollapseBtn = getCollapseBtn(styled)
const Content = getContent(styled)
//const Footer = getFooter(styled)
const standardScheme = getStandardScheme()
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const mainLanguage = process.env.REACT_APP_MAIN_LANGUAGE
let defaultLanguage
switch (mainLanguage) {
	case 'FR':
		defaultLanguage = LOCALES.FRENCH
		break
	case 'EN':
		defaultLanguage = LOCALES.ENGLISH
		break
	case 'DE':
		defaultLanguage = LOCALES.GERMAN
		break
	case 'ES':
		defaultLanguage = LOCALES.SPANISH
		break

	default:
		defaultLanguage = LOCALES.ENGLISH
		break
}

function App() {
	const [config, setConfig] = useState({})
	const [races, setRaces] = useState([])
	const [locale, setLocale] = useStateWithLocalStorage('locale', defaultLanguage)
	const [isDarkMode, setIsDarkMode] = useStateWithLocalStorage('isDarkMode')

	const [data] = useState({
		header: true,
		nav: true,
		content: true,
		footer: false, //rallyType === 'DKC' ? false : true,
	})

	const { enableLinkTracking, trackPageView } = useMatomo()
	enableLinkTracking()

	let vh = window.innerHeight * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)
	window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	})

	const { loading: configLoading, error: configError, data: configData } = useQuery(CONFIG_QUERY)
	const { loading: racesLoading, error: racesError, data: racesData } = useQuery(RACES_QUERY)

	useEffect(() => {
		trackPageView()
	}, [trackPageView])

	useEffect(() => {
		setLocale(locale)
		document.body.dir = locale === 'ar-SA' ? 'rtl' : 'ltr'
	}, [locale, setLocale])

	useEffect(() => {
		if (!configLoading && configData) {
			setConfig(configData.config)
		}
	}, [configLoading, configData])

	useEffect(() => {
		if (!racesLoading && racesData) {
			setRaces(racesData.races)
		}
	}, [racesLoading, racesData])

	const { rallyCode, primaryColor, secondaryColor } = config

	const theme = createTheme({
		direction: locale === 'ar-SA' ? 'rtl' : 'ltr',
		palette: {
			type: isDarkMode ? 'dark' : 'light',
			primary: {
				main: config.primaryColor ? primaryColor : isDarkMode ? '#ffffff' : '#949494',
			},
			secondary: {
				main: config.secondaryColor ? secondaryColor : isDarkMode ? '#ffffff' : '#757575',
			}, //contrastThreshold: 8,
			tableBackground: isDarkMode ? '#424242' : '#fff',
			tableBackgroundOdd: isDarkMode ? '#757575' : '#e9e9e9',
			tableBackgroundHeader: isDarkMode ? '#424242' : '#fff',
		},
		typography: {
			fontSize: 13, //NOTE: all application font-size
		},
		overrides: {
			MuiDrawer: {
				root: {
					overflow: 'hidden',
				},
			},
			MuiToggleButton: {
				// Override the styling for selected toggle buttons
				root: {
					'&$selected': {
						backgroundColor: secondaryColor,
						color: '#ffffff',
					},
				},
			},
		},
	})

	const useStyles = makeStyles(theme => ({
		root: {
			height: '100%',
		},
		header: {
			padding: 2,
			backgroundColor: primaryColor ? primaryColor : isDarkMode ? '#212121' : '#f5f5f5',
		},
		content: {
			height: `calc((var(--vh, 1vh) * 100) - 64px)`,
		},
		footer: {
			//marginTop: '-15px',
			//marginBottom: 25,
			//bottom: 0
		},
	}))
	const classes = useStyles()

	standardScheme.enableAutoCollapse('primarySidebar', 'xl')
	standardScheme.configureEdgeSidebar(builder => {
		builder.update('primarySidebar', config => {
			config.sm.collapsedWidth = 66
			config.xs.width = 250
			config.xs.headerMagnetEnabled = true
		})
	})

	const changeLocale = lang => {
		setLocale(lang)
	}

	//if (configLoading || racesLoading) return 'Loading...'
	if (configError) return `Error! ${configError && configError.message}`
	if (racesError) return `Error! ${racesError && racesError.message}`

	return !!races.length && !racesLoading && !!Object.keys(config).length && !configLoading ? (
		<ConfigProvider config={config}>
			<RacesProvider races={races}>
				<OptionsProvider>
					<IntlProvider locale={locale} messages={messages[locale]}>
						<StylesProvider jss={jss}>
							<Root scheme={standardScheme} className={classes.root}>
								{({ state: { sidebar }, setOpen }) => (
									<Location>
										{({ location }) => (
											<ThemeProvider theme={theme}>
												<CssBaseline />
												<Header className={classes.header}>
													<Toolbar>
														<SidebarTrigger sidebarId="primarySidebar" />
														{data.header && (
															<AppHeader
																locale={locale}
																changeLocale={changeLocale}
																LOCALES={LOCALES}
															/>
														)}
													</Toolbar>
												</Header>
												<DrawerSidebar sidebarId="primarySidebar">
													<SidebarContent>
														{data.nav && (
															<NavContent
																rallyCode={rallyCode}
																location={location}
																sidebar={sidebar}
																setOpen={setOpen}
																collapsed={sidebar.primarySidebar.collapsed}
															/>
														)}
													</SidebarContent>
													<CollapseBtn />
												</DrawerSidebar>
												<Content className={classes.content}>
													{data.content && (
														<Router>
															<RankingRT path="/stages" default />
															<RankingCHS path="/chs" />
															{config.rallyType !== 'TAO' && <RankingOV path="/overall" />}
															<Departures path="/departures" />
															<StartList path="/startlist" />
															<Pdf path="/pdf" />
															<Settings
																path="/settings"
																themeChange={setIsDarkMode}
																isDarkMode={isDarkMode}
															/>
														</Router>
													)}
												</Content>
												{/*	<Footer className={classes.footer}>{data.footer && <AppFooter />}</Footer>*/}
												<CookieConsent
													style={{
														fontSize: '10px',
														textAlign: 'center',
													}}
												>
													This website uses essential functional cookies to enhance the user
													experience.
												</CookieConsent>
											</ThemeProvider>
										)}
									</Location>
								)}
							</Root>
						</StylesProvider>
					</IntlProvider>
				</OptionsProvider>
			</RacesProvider>
		</ConfigProvider>
	) : (
		<Loader />
	)
}

export default App
