import { useState, useEffect } from 'react'
import { axiosInstance } from '../utils/restAPI'
import { Link } from '@material-ui/core'
import { TreeView, TreeItem } from '@material-ui/lab/'
import {
	//ExpandMore,
	//ChevronRight,
	PictureAsPdfOutlined,
	Folder,
	FolderOpen,
} from '@material-ui/icons/'
import { makeStyles } from '@material-ui/core/styles'
import * as appStorage from '../utils/appStorage'

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
	},
	treeRoot: {
		marginTop: 15,
		marginLeft: 15,
		//height: '100%',
		flexGrow: 1,
		maxWidth: 600,
	},
	icon: {
		color: theme.palette.secondary.main,
	},
	folder: {
		//color: theme.palette.secondary.main
		//color: '#ffffff'
		//fontSize: '2em'
	},
}))

function Pdf() {
	const classes = useStyles()
	const [pdfTree, setPdfTree] = useState(null)
	const [expanded, setExpanded] = useState([])
	const [selected, setSelected] = useState([])
	//console.log(localStorage)

	useEffect(() => {
		const locale = appStorage.get('locale')
		axiosInstance.get('/getTreePDF', { params: { locale: locale } }).then(res => {
			setPdfTree(res.data)
		})
	}, [])

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds)
	}

	const handleSelect = (event, nodeIds) => {
		setSelected(nodeIds)
	}

	const processFolder = folder => {
		return (
			<TreeItem
				key={folder.id}
				nodeId={String(folder.id)}
				label={folder.name}
				classes={{ label: classes.folder }}
			>
				{folder.files.map((item, j) => {
					return item.isFolder ? processFolder(item) : processFile(item)
				})}
			</TreeItem>
		)
	}

	const processFile = file => {
		return (
			<TreeItem
				key={file.id}
				nodeId={String(file.id)}
				icon={<PictureAsPdfOutlined className={classes.icon} />}
				label={
					<Link
						key={file.id}
						target="_blank"
						rel="noreferrer"
						color="inherit"
						href={`${file.path}`}
					>
						{file.name}
					</Link>
				}
			/>
		)
	}

	return (
		<div className={classes.root}>
			<TreeView
				className={classes.treeRoot}
				//			defaultCollapseIcon={<ExpandMore />}
				//		defaultExpandIcon={<ChevronRight />}
				defaultCollapseIcon={<FolderOpen className={classes.icon} />}
				defaultExpandIcon={<Folder className={classes.icon} />}
				expanded={expanded}
				selected={selected}
				onNodeToggle={handleToggle}
				onNodeSelect={handleSelect}
			>
				{pdfTree && pdfTree.map(item => (item.isFolder ? processFolder(item) : processFile(item)))}
			</TreeView>
		</div>
	)
}

export default Pdf
