import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { MUITable } from '../components/MUITable'
import { useConfig } from '../hooks'
import { MenuListRaces } from '../components/SubMenus'

const useStyles = props =>
	makeStyles(theme => ({
		root: {
			position: 'static',
		},
		rtMenu: {
			padding: 2,
			marginTop: 5,
			width: '90%',
			overflowX: 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			scrollbarWidth: 'none',
		},
		rtButton: {
			//height: '30px'
			//		backgroundColor: theme.palette.secondary.main,
			//	color: 'white',
			borderColor: theme.palette.secondary.main,
			color: theme.palette.secondary.main,
			borderRadius: '1em',
			padding: 5,
			whiteSpace: 'nowrap',
			//width: `${props.rallyType === 'TAO' ? '80px' : 'auto'}`,
		},
		rtInactive: {
			textDecoration: 'line-through',
		},
		disabledButton: {
			//color: '#aaaaff'
			//backgroundColor: '#ffffff'
		},
		rootMessage: {
			width: '100%',
			//padding: '16px',
			textAlign: 'center',
		},
		left: {},
		middle: {},
		right: {
			textAlign: 'right',
		},
		headerText: {
			fontWeight: 'bold',
			minWidth: 0,
			fontSize: 12,
			textAlign: 'center',
			marginLeft: '5px',
			//color: 'red',
		},
		'@keyframes blinker': {
			from: { opacity: 1 },
			to: { opacity: 0.5 },
		},
		blink: {
			animationDuration: '2s',
			animationName: '$blinker',
			animationIterationCount: 'infinite',
			animationDirection: 'alternate',
			animationTimingFunction: 'ease-in-out',
		},
	}))

const CHS_LIST_QUERY = gql`
	{
		chsList {
			id
			name
			race {
				id
				name
			}
			isActive
		}
	}
`

const RANKING_CHS_QUERY = gql`
	query rankingCHSQuery($raceID: ID) {
		rankingCHS(raceID: $raceID) {
			raceNumber
			teamName
			coefficientDisplay
			model
			race {
				text
			}
			results
		}
	}
`

function RankingCHS() {
	const { config } = useConfig()
	const { mixDisplayRaces: isMix } = config

	const classes = useStyles()
	const [selectedRace, setSelectedRace] = useState(null)
	const [chsList, setCHSList] = useState([])
	const [rankingCHS, setRankingCHS] = useState([])

	const {
		loading: chsListLoading,
		error: chsListError,
		data: chsListData,
	} = useQuery(CHS_LIST_QUERY)

	const {
		loading: rankingCHSLoading,
		error: rankingCHSError,
		data: rankingCHSData,
	} = useQuery(RANKING_CHS_QUERY, {
		pollInterval: config.pollInterval || 60000,
		variables: { raceID: selectedRace },
	})

	useEffect(() => {
		if (!rankingCHSLoading && rankingCHSData) {
			setRankingCHS(rankingCHSData.rankingCHS)
		}
	}, [rankingCHSLoading, rankingCHSData])

	const columnsCHS = [
		{
			Header: '#',
			accessor: 'raceNumber',
			sticky: 'left',
			maxWidth: 30,
			Cell: ({ value }) => <span className={classes.numberSpan}>{value}</span>,
		},
		{
			Header: <FormattedMessage id="crew" />,
			id: 'teamName',
			maxWidth: 150,
			sticky: 'left',
			accessor: row =>
				`${row.teamName}<br /><span style="font-size:70%;padding-left:20px;">${row.model}</span>`,
			Cell: ({ cell }) => <div dangerouslySetInnerHTML={{ __html: cell.value }} />,
		},
	]

	if (chsList) {
		for (const [i, value] of chsList.entries()) {
			columnsCHS.push({
				Header: <span className={classes.numberSpan}>{value.name}</span>,
				accessor: 'results[' + i + ']',
				maxWidth: 60,
				id: value.name,
				Cell: ({ cell }) => <span className={classes.numberSpan}>{cell.value}</span>,
			})
		}
	}

	useEffect(() => {
		if (chsListData) {
			const data = isMix
				? chsListData.chsList
				: chsListData.chsList.filter(chs => chs.race.id === selectedRace)
			setCHSList(data)
		}
	}, [selectedRace, setCHSList, chsListData, isMix])

	if (chsListLoading || rankingCHSLoading) return 'Loading...'
	if (chsListError || rankingCHSError)
		return `Error! ${chsListError && chsListError.message} ${
			rankingCHSError && rankingCHSError.message
		}`

	return (
		<>
			<div className={classes.root}>
				{!isMix && (
					<MenuListRaces
						rankingSelectedRace={selectedRace}
						setRankingSelectedRace={setSelectedRace}
						classes={classes}
						rankingType={'CHS'}
					/>
				)}
			</div>
			{rankingCHS && (
				<>
					<div className={classes.table}>
						<MUITable columns={columnsCHS} data={rankingCHS} type="rankingCHS" isMix={isMix} />
					</div>
				</>
			)}
		</>
	)
}

export default RankingCHS
