import { useState } from 'react'
import * as appStorage from '../utils/appStorage'

const useStateWithLocalStorage = (key, initialValue = null) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = appStorage.get(key)
			// Parse stored json or if none return initialValue
			//return item ? JSON.parse(item) : initialValue
			return item ? item : initialValue
		} catch (error) {
			// If error also return initialValue
			console.log(error)
			return initialValue
		}
	})

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = value => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			//appStorage.set(key, JSON.stringify(valueToStore))
			appStorage.set(key, valueToStore)
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}
	//console.log(localStorage)
	return [storedValue, setValue]
}

export default useStateWithLocalStorage
