import { LOCALES } from '../locales'

const localesDE = {
	[LOCALES.GERMAN]: {
		//MENU
		rankingsRT: 'Gleichmäßigkeitsprüfung',
		rankingsGLP: 'Gleichmäßigkeitsprüfung',
		rankingsZR: 'Gleichmäßigkeitsprüfung',
		rankingsEC: 'Timed Stages',
		rankingsSS: 'Special Stages',
		rankingsOV: 'Gesamt Ergebnis',
		departures: 'Startzeiten',
		startList: 'Teilnehmerliste',
		//COLUMNS
		position: 'Pos',
		experience: 'Exp',
		crew: 'Besatzung',
		car: 'Wagen',
		vehicle: 'Vehicle',
		total: 'Gesamt',
		time: 'Zeit',
		difference: 'Untersch.',
		differenceFirst: 'Untersch. 1',
		year: 'Jahr',
		groupShort: 'Gr',
		classShort: 'Kl',
		group: 'Gruppe',
		class: 'Klasse',
		capacity: 'Hubraum',
		race: 'Kategorie',
		avg: 'Durchschn.',
		coef: 'Koeff.',
		team: 'Team',
		period: 'Period',
		plateau: 'Plateau',
		rt: 'RT',
		rz: 'RZ',
		ec: 'EC',
		ss: 'SS',
		ch: 'ZK',
		sc: 'Geschw.',
		cp: 'CP',
		chs: 'ZKS',
		pen: 'Straf',
		joker: 'Joker',
		timeAM: '{am} Time',
		timePM: '{pm} Time',
		firstRZ: '1st RZ',
		leg: 'Leg',
		day: 'Day',
		stage: 'Stage',
		//BOUTONS
		summary: 'Zsfass.',
		detailed: 'Detail',
		overall: 'Allgemeine',
		rtSummary: 'RT Zsfass.',
		rtDetailed: 'RT Detail',
		rzSummary: 'RZ Summary',
		rzDetailed: 'RZ Detailed',
		ovSummary: 'Allgemeine Zsfass.',
		ovDetailed: 'Allgemeine Detail',
		gcSummary: 'GC Summary',
		gcDetailed: 'GC Detailed',
		//TOOLTIPS
		TTcarNumber: 'Geben Sie eine Autonummer ein, um sie hervorzuheben',
		TTgroup: 'Gruppe',
		TTgroupPos: 'Gruppenposition',
		TTclass: 'Klasse',
		TTclassPos: 'Klassenposition',
		TTch: 'Zeitkontrolle',
		TTsc: 'Geschwindigkeitsstrafen',
		TTcp: 'Durchfahrtskontrolle',
		TTpen: 'Andere Strafen',
		//MISC
		canceled: 'ABGESAGT',
		all: 'Alle',
	},
}

export default localesDE
