import { Tabs, Tab } from '@material-ui/core'
import { useIntl } from 'react-intl'

//import { makeStyles } from '@material-ui/core/styles'

/*const useStyles = makeStyles(theme => ({
	footerOV: {
		marginTop: `calc(5% + ${60}px)`,
		bottom: 0,
		position: 'sticky'
	}
}))*/

const FooterOV = ({ viewTypeOV, setViewTypeOV }) => {
	//const classes = useStyles()
	const intl = useIntl()
	return (
		<Tabs
			value={viewTypeOV}
			onChange={(event, value) => {
				if (value !== null) setViewTypeOV(value)
			}}
			indicatorColor="secondary"
			//textColor="primary"
			variant="fullWidth"
			aria-label="full width tabs example"
			//className={classes.footerOV}
		>
			<Tab label={intl.formatMessage({ id: 'gcSummary' })} value="summary" />
			<Tab label={intl.formatMessage({ id: 'gcDetailed' })} value="detailed" />
		</Tabs>
	)
}
//FooterOV.propTypes = {}
//FooterOV.defaultProps = {}

export default FooterOV
